import styled, {css} from 'styled-components';

const paddings = css`
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const Title1 = styled.h1`
  font-size: 4rem;
  line-height: 4.5rem;
  font-family: 'Raleway', Georgia, sans-serif;
  color: ${({theme}) => theme.colors.primary_text};
  font-weight: 400;
  text-align: left;
  margin-bottom: 4.375rem;
  ${paddings};
  text-transform: uppercase;
`;

export const Title2 = styled.h2`
  font-size: 1.625rem;
  line-height: 2.5rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Raleway', Georgia, sans-serif;
  /* color: ${({theme}) => theme.colors.primary}; */
  color: #002039;
  text-align: center;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    font-size: 2rem;
    line-height: 3rem;
  }

  @media (min-width: 992px) {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }

  @media (min-width: 1200px) {
    font-size: 3.5rem;
    line-height: 4rem;
  }
`;

export const Title3 = styled.h3`
  font-size: 1.375rem;
  line-height: 2.25rem;
  font-family: 'Raleway', Georgia, sans-serif;
  color: ${({theme}) => theme.colors.text_dark};
  font-weight: 600;

  @media (min-width: 992px) {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 2.25rem;

  ${({noBtmMargin}) => noBtmMargin && `
    margin-bottom: 0;
  `
  }

  @media (min-width: 576px) {
    font-size: 1.125rem;
    line-height: 3rem;
  }
`;