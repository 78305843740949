import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Seo from '../components/seo';
import CookieConsent from "react-cookie-consent";
import { ContentWrapper } from '../components/common';
import { Title1 } from '../components/headings';
import { HOME, PRIVACY_POLICY } from '../routes';
import { Link } from 'gatsby';
import logoSrc from '../images/logo-hutisko.png';
import theme from '../theme';

const ConsentContent = styled.div`
  a {
    color: ${({theme}) => theme.colors.text_primary};
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.secondary};

  img {
    width: 100%;
    height: auto;
    max-width: 240px;
    margin-bottom: 2rem;
  }

  @media (min-width: 768px) {
    img {
      max-width: 320px;
    }
  }
`;

const StyledLink = styled(Link)`
  font-size: 1.125rem;
  line-height: 1.875rem;
  color: #fff;
  background-color: ${({theme}) => theme.colors.primary};
  border: 0;
  width: 100%;
  font-weight: 400;
  padding: 0.75rem 2.25rem;
  transition: all .3s ease;
  border-radius: 8px;
  border: 2px solid ${({theme}) => theme.colors.primary};

  &:hover {
    text-decoration: none;
    color: ${({theme}) => theme.colors.primary};
    background-color: transparent;
  }
`;

const Container = styled.div`
  padding: 7rem 0;
  text-align: center;

  ${Title1} {
    font-size: 5rem;
    line-height: 6.25rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0;
    color: #fff;
    text-align: center;
  }

  p {
    font-size: 1.25rem;
    line-height: 3.75rem;
    margin-bottom: 2.5rem;
    color: #fff;
  }
`;

const NotFoundPage = () => (
  <ThemeProvider theme={theme}>
    <CookieConsent
      location="bottom"
      buttonText="Rozumím"
      cookieName="hu-cookie-consent"
      style={{ background: "#fff", color: "#666666", fontSize: "0.875rem" }}
      buttonStyle={{ backgroundColor: "#caad72", color: "#fff", borderRadius: "5px", fontSize: "1rem", padding: "0.5rem 1rem" }}
      expires={150}
    >
      <ConsentContent>
        Tento web používá k&nbsp;poskytování služeb, personalizaci reklam a&nbsp;analýze návštěvnosti soubory cookie. Používáním tohoto webu s&nbsp;tím souhlasíte. <a href={PRIVACY_POLICY} target="_blank" rel="noreferrer" title="Zobrazit soubor - Informace o zpracování osobních údajů">Ochrana osobních údajů</a>
      </ConsentContent>
    </CookieConsent>
    <Wrapper>
      <Seo title="Stránka nebyla nalezena" />
      <Container>
        <ContentWrapper>
          <img src={logoSrc} alt="Logo - Apartmány Hutisko" />
          <Title1>404</Title1>
          <p>Stránka nebyla nalezena</p>
          <StyledLink to={HOME}>Přejít na hlavní stránku</StyledLink>
        </ContentWrapper>
      </Container>
    </Wrapper>
  </ThemeProvider>
)

export default NotFoundPage;
